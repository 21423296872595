const storeKeys = {
  StoreMilestoneIndex: "MilestoneIndex",
  StoreNavigationIndex: "NavigationIndex",
  ShowLoader: "ShowLoader",
  IsAnalyticsDataLoaded: "IsAnalyticsDataLoaded",
  AnalyticsData: "AnalyticsData",
  ApplicationReducer: "applicationReducer",
  LoggedInUserReducer: "loggedInUserReducer",
  MasterReducer: "masterReducer",
  PolicyReducer: "policyReducer",
  VersionReducer: "versionReducer",
  SchemaReducer: "schemaReducer",
  MasterCoverageTemplates: "coverageTemplates",
  MultipleRatingResponse: "multipleRate",
  QueriesReducer: "queriesReducer",
  DataReducer: "dataReducer"
}

const requestKeys = {
  HelloSign: "/Application/Summary-HelloSign",
  ResendSign: "/Application/Resend-HelloSign",
  CreateNewVersion: "CreateNewVersion",
  UpdateQuoteVersions: "UpdateQuoteVersions",
  GetPremiumFinance: "/GetPremiumFinance",
  GetInspectionFee: "GetInspectionFee",
  GeneratePolicyNumber:
    "/Personal/HomeOwners/Application/Summary/GeneratePolicyNumber",
  ApplicationCoverageRules: "/Application/Coverage-Rules",
  BillingNEW: "/Personal/HomeOwners/Application/Summary-BillingNEW",
  BillingCANCEL: "/Personal/HomeOwners/Application/Summary-BillingCANCEL",
  BillingREINSTATE: "/Personal/HomeOwners/Application/Summary-BillingREINSTATE",
  BillingENDORSEMENT:
    "/Personal/HomeOwners/Application/Summary-BillingENDORSEMENT",
  BillingGETCANCELBALANCE:
    "/Personal/HomeOwners/Cancel/Landing-BillingGETCANCELBALANCE",
  BillingSTATEMENTPROJECTION:
    "/Personal/HomeOwners/Application/Summary-BillingSTATEMENTPROJECTION",
  BillingPAYPLANRULES: "/Application/Coverage-BillingPAYPLANRULES"
}

const UserFeaturesKeys = {
  QuoteIndication: "Quote Indication",
  FormalQuote: "Formal Quote",
  ApproveReferrals: "Approve Referrals",
  BindRequestAwaitingUWApproval: "Request to Bind",
  PolicyInForce: "Bind",
  Issue: "Issue",
  StartRenewal: "Start Renewal",
  RequestEndorsement: "Request Endorsement",
  OOS: "OOS",
  RequestCancellation: "Request Cancellation",
  Cancel: "Cancel",
  Reinstate: "Reinstate",
  BackdateEffEndorseDates: "Backdate Eff / Endorse Dates",
  ViewRatingWorksheet: "View Rating Worksheet",
  PrintRatingWorksheet: "Print Rating Worksheet",
  DownloadPrintDocs: "Download / Print Docs",
  LockAccounts: "Lock Accounts",
  Reporting: "Reporting",
  UnlockUsers: "Unlock Users",
  RollbackEndorsement: "Rollback Endorsement",
  UserEntityManagement: "User/Entity Management",
  ManageHelpText: "Manage Help Text",
  EditQuestions: "Edit Questions",
  EditPickLists: "Edit Pick Lists",
  UpdateRates: "Update Rates",
  UpdateFormRules: "Update Form Rules",
  AddRemoveForms: "Add / Remove Forms",
  MessagingOnPlatform: "Messaging on Platform",
  ManageIntegrationCredentials: "Manage Integration Credentials",
  EmailTemplateManagement: "Email Template Management",
  OtherItemsToBeDiscussed: "Other items to be discussed",
  ViewOnlyAccess: "View Only Access",
  BindWithoutDocs: "Bind w/out Docs",
  OverrideDecline: "Override Decline",
  BindWithoutPay: "Bind w/out Pay",
  WaiveMEP: "Waive MEP",
  OverrideFullyEarnedFees: "Override Fully Earned Fees",
  OverrideCancPremium: "Override Canc Premium",
  FlatCancellation: "Flat Cancellation",
  InsuredDirectBilled: "Insured Bill"
}

const payOptionsKeys = {
  Full: "full",
  IPFS: "ipfs",
  OutsidePF: "outsidepf"
}

const apolloKeys = {
  GetAll: "getAll",
  GetByFilter: "getByFilter",
  GetMultipleratingByFilter: "getComparativeRater",
  VersionUpdate: "versionUpdate",
  LoadUsersData: "loadUsersData",
  LoadMasters: "loadMasters",
  FetchCoverageOption: "fetchCoverageOption",
  PostMutation: "postItem",
  PutMutation: "putItem",
  RateMutation: "rateNewQuote",
  BindMutation: "saveAndBindNewBusiness",
  BindAction: "bind",
  MultipleRaterMutation: "MultipleRate",
  SaveSelectedIndex: "multipleRatesSave",
  RateAction: "RateNewQuote",
  OfferQuoteAction: "OfferQuote",
  OfferQuoteMutation: "offerQuote",
  UpdatePolicyStatus: "updatePolicyStatus",
  MultipleRateAction: "multipleRating",
  SaveMultipleAction: "saveMultiple",
  AuthenticateUser: "authenticateUser",
  PostToNotificationAPI: "postToNotificationAPI",
  ValidateOTP: "validateOTP",
  QueryByContainer: "queryByContainer",
  GetForms: "getForms",
  GetCoverageTemplate: "getTemplate",
  AddCoverageTemplate: "insertTemplate",
  MultipleBindAction: "multipleBind",
  MultipleBindMutation: "multipleBind",
  GetAnalyticsData: "getAnalytics",
  RenewPolicy: "renewPolicy",
  SaveConflicts: "saveConflicts",
  ProcessOOS: "processOutOfSequence",
  GetHash: "getHashing",
  GetAgents: "getAgents",
  GetAgencies: "getAgencies",
  GetUnderwriters: "getUnderwriters",
  StartEndorsement: "startEndorsement",
  RateEndorsement: "rateEndorsement",
  BindEndorsement: "bindEndorsement",
  StartCancellation: "startCancellation",
  BindCancellation: "bindCancellation",
  StartReinstate: "startReinstate",
  BindReInstate: "bindReinstate",
  RevertTransaction: "revertTransaction",
  SummaryOOS: "summaryOOS",
  DummyMutation: "dummyMutation"
}

const commonKeys = {
  Code: "Code",
  Description: "Description",
  Value: "Value",
  Text: "Text",
  IsErrored: "IsErrored",
  Valid: "Valid",
  Errors: "Errors",
  EmptyString: "",
  DefaultTemplate: "Default Template",
  ModuleQuote: "Quote",
  ModuleApplication: "Application",
  ModuleEndorsement: "Endorsement",
  ModuleRenewal: "Renewal",
  ModuleCancel: "Cancel",
  ModuleReinstate: "Reinstate",
  RoleAgent: "Agent",
  RoleBuilder: "Builder",
  Agency: "Agency",
  RoleOutsideMGA: "OutSideMGA",
  RoleUW: "UW",
  RoleSUW: "SUW",
  RoleUnderwriter: "Underwriter",
  ActionAllow: "Allow",
  ActionRefer: "Refer",
  ActionBlock: "Block",
  ActionInEligible: "InEligible",
  ActionEmailAgent: "agentEmail",
  ActionEmailUnderWriter: "underwriterEmail",
  ActionEmailFormalQuote: "EmailFormalQuote",
  ActionPrintQuote: "PrintQuote",
  ActionDownloadRatesheet: "DownloadRatesheet",
  ActionSmsQuote: "SmsQuote",
  ActionEmailAndDownload: "ActionEmailAndDownload",
  NotificationTypeSMS: "S",
  NotificationTypeEmail: "E",
  NotificationFormatHTML: "html",
  NotificationFormatPDF: "pdf",
  NotificationFormatTEXT: "text",
  SubjectInvalidAddr: "Invalid Latitude/Longitude Notification",
  TemplateNameNotificationAgent: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/Notifications/templateForAgent.html`,
  TemplateNameNotificationBind: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/Notifications/bindsuccessmailbody.html`,
  TemplateNameNotificationUnderWriter: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/Notifications/templateForUnderWriter.html`,
  TemplateNameNotificationInvalidAddrUW: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/Notifications/InvalidAddressMailforUW.html`,
  TemplateNameNotificationFormalQuote: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/Notifications/formalquote.html`,
  TemplateNameNotificationFormalQuoteMessageBody: "toga/commercial/auto/common/formalquotemessagebody.html",
  TemplateNameCancelReinstate: "toga/commercial/auto/common/REI_CAN_document.html",
  TemplateMapRiskKCCDocument: "diep2/EMBARK/templates/Personal/HO5/common/ThirdParty/MapRiskKCC.html",
  MapRiskKCCDocument: "MapRisk KCC PDF",
  TemplateMapRiskReportKCCDocument: "diep2/EMBARK/templates/Personal/HO5/common/ThirdParty/MapRiskReportKCC.html",
  MapRiskReportKCCDocument: "MapRisk Report KCC PDF",
  TemplateFLRateSheet: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/RateSheets/EmbarkRateSheet.html`,
  TemplateNameInsuredPaymentConfirmation: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/HO5/common/Notifications/agentpaymentconfirmation.html`,
  TemplateNamePaymentRequest: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/HO5/common/Notifications/agentpaymentconfirmation.html`,
  TemplateNameAgentPaymentConfirmation: `${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/HO5/common/Notifications/agentpaymentconfirmation.html`,
  TrueString: "true",
  FalseString: "false",
  PageChangeFormName: "pageChangeForm",
  New: "new",
  SubmitAndAddAnother: "submitAndAddAnother",
  SubmitOnly: "SubmitOnly",
  CloseModal: "closeModal",
  Completed: "completed",
  Errored: "errored",
  Deleted: "Deleted",
  DatatTableMode: "checkbox",
  AddNewDriver: "addnewDriver",
  Disabled: "disabled",
  Disable: "disable",
  Selected: "selected",
  Options: "options",
  FMCSA: "FMCSA",
  YesString: "Yes",
  NoString: "No",
  TemplateNameDeclineSubmission:
    "toga/templates/commercial/auto/common/notifications/submissiondecline.html",
  TemplateNameHoldSubmission:
    "toga/templates/commercial/auto/common/notifications/submissionhold.html",
  TemplateNameHazardHubDocument: `diep2/${process.env.NEXT_PUBLIC_CLIENT}/templates/Personal/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/common/ThirdParty/templateForHazardHub.html`,
  ThirdPartyDocument: "Third Party Document",
  Communications: "Communications",
  Email: "Email",
  PhoneNumber: "Phone Number",
  UnderWriter: "UnderWriter",
  Reason: "Reason",
  OldAddress: "oldAddress"
}

const axiosKeys = {
  PostAction: "POST",
  GetAction: "GET"
}

const ModalSchemaKeys = {
  AdjustPricing: "/ActionModal/AdjustPricing",
  ApproveAndOfferQuote: "/ActionModal/ApproveAndOfferQuote",
  EditEffectiveDate: "/ActionModal/EditEffectiveDate",
  BindAndIssue: "/ActionModal/BindAndIssue",
  DeclineBindRequest: "/ActionModal/DeclineBindRequest",
  BindRequest: "/ActionModal/BindRequest",
  HoldBindRequest: "/ActionModal/HoldBindRequest",
  OnDeclineReferral: "/ActionModal/OnDeclineReferral",
  SignDocuments: "/ActionModal/SignDocuments",
  OnHoldEnd: "/ActionModal/OnHoldEnd",
  OnHold: "/ActionModal/OnHold",
  RejectEnd: "/ActionModal/RejectEnd",
  StartEndorsement: "/ActionModal/StartEndorsement"
}

const tabKeys = {
  Application: "application",
  Coverages: "coverages",
  Ratings: "rating",
  Documents: "documents",
  PolicyAnalytics: "policyanalytics",
  QuoteAnalytics: "quoteanalytics",
  UnderwriterAnalytics: "underwriteranalytics",
  Endorsement: "endorsement",
  AnnualPremium: "Annual Premium",
  Transactions: "transactions",
  CommunicationHub: "communicationhub",
  OOSESummary: "oosesummary",
  BillingProjection: "billingprojection"
}

const layoutKeys = {
  PageNoLayout: "L1",
  PageLayoutTwo: "L2",
  PageLayoutThree: "L3"
}

const templateKeys = {
  InsuredSignVerification: "InsuredSignVerification",
  SMSQuote: "SMSQuote",
  CancellationOTP: "CancellationOTP",
  InsuredOTPApplication: "InsuredOTPApplication",
  BindNotificationToInsured: "BindNotificationToInsured",
  ReinstatementOTP: "ReinstatementOTP",
  SubmitApprovalNotification: "SubmitApprovalNotification", //UW approval request added explicitly
  ApplicationOnHoldByUW: "ApplicationOnHoldByUW",
  ApplicationRejectedByUW: "ApplicationRejectedByUW",
  ApplicationRejectedByAgent: "ApplicationRejectedByAgent"
}

const summaryActionButtonsKeys = {
  QuoteOffered: "Quote Offered",
  All: "All",
  EditSubmission: "EditSubmission",
  UnderwrittingRules: "UnderwrittingRules",
  AssignedUWDetails: "AssignedUWDetails", //New
  AdjustPricingAndCommission: "AdjustPricingAndCommission",
  EditQuote: "EditQuote",
  DeclineQuote: "DeclineQuote",
  EndorsePolicy: "EndorsePolicy",
  CancelPolicy: "CancelPolicy",
  ReviewCancellation: "Review Cancellation Request",
  Reinstate: "Reinstate",
  DeclineBindRequest: "DeclineBindRequest",
  OnHoldBindRequest: "OnHoldBindRequest",
  AddOrRemoveOptionalForm: "AddOrRemoveOptionalForm", //Add/RemoveOptional Form
  ResendSignatureRequest: "Resend for Signature",
  SignatureButtons: "Resend for Signature",
  SubmitForApproval: "SubmitForApproval",
  RejectAndHold: "RejectAndHold",
  PremiumAgencyUW: "PremiumAgencyUW",
  Approve: "Approve",
  ApproveAndDecline: "ApproveAndDecline",
  FullSubmission: "FullSubmission",
  ApproveAndOfferQuote: "ApproveAndOfferQuote", //change
  DeclineSubmission: "DeclineSubmission",
  SubmitBindRequest: "SubmitBindRequest",
  UnderReview: "UnderReview",
  MakePayment: "MakePayment",
  RequestCoverageBound: "RequestCoverageBound",
  // CopySubmission: "CopySubmission",
  RefreshSubmission: "RefreshSubmission",
  BindIssuePolicy: "BindIssuePolicy",
  SignDocuments: "SignDocuments",
  PolicyReject: "PolicyReject",
  PolicyOnHold: "PolicyOnHold",
  ReinstatePolicy: "ReinstatePolicy",
  ResendSignatureDocuments: "ResendSignatureDocuments",
  Editeffectivedate: "Editeffectivedate"
}

const flowKeys = {
  Quote: "Quote",
  Application: "Application",
  Endorsement: "Endorsement",
  Renewal: "Renewal",
  ReInstate: "ReInstate",
  Cancel: "Cancel",
  Modals: "Modals",
  AccountHub: "AccountHub"
}

const dateKeys = {
  Seconds: "Seconds",
  Minutes: "Minutes",
  Hours: "Hours",
  Days: "Days",
  Weeks: "Weeks",
  Months: "Months",
  Years: "Years",
  DateFormatyyymmdd: "YYYY-MM-DD"
}

const UnderwritingKeys = {
  NoOfClaims: "NoOfClaims",
  AddMortgageeSection: "add-MortgageeSection",
  AddAdditionalInsuredSection: "add-AdditionalInsuredSection",
  AddAdditionalInterestSection: "add-AdditionalInterestSection",
  AddTrustSection: "add-TrustSection",
  MDSection: "MortgageeSection",
  AISection: "AdditionalInsuredSection",
  AIntSection: "AdditionalInterestSection",
  TrustSection: "TrustSection",
  AnyResidenceEmployees: "anyResidenceEmployees",
  IsAnyOtherInsuranceWithCompany: "isAnyOtherInsuranceWithCompany",
  HadForeclosureRepossessionOrBankruptcy:
    "hadForeclosureRepossessionOrBankruptcy",
  StatusForForeclosureRepossessionOrBankruptcy:
    "statusForForeclosureRepossessionOrBankruptcy",
  IsExoticAnimalOrPet: "isExoticAnimalOrPet",
  IsAnimalWithBiteHistory: "isAnimalWithBiteHistory",
  IsPropertyMoreThanFiveAcres: "isPropertyMoreThanFiveAcres",
  OtherStructureOnPremise: "otherStructureOnPremise",
  IsUnderRenovationOrReconstruction: "isUnderRenovationOrReconstruction",
  IsAnySwimingPool: "isAnySwimingPool",
  IsThePropertyCurrentlyInsured: "isThePropertyCurrentlyInsured",
  PayMethod: "paymethod",
  Payplan: "payplan"
}

const FeesAndTaxesKeys = {
  PFEE: "PFEE",
  INSFEE: "INSFEE"
}

const cancellationKeys = {
  schemaKeys: {
    BusinessName: "businessname",
    MailingAddress: "mailingaddress",
    LOB: "lineOfBusiness",
    EffectiveDate: "policyeffectivedate",
    ExpiryDate: "policyenddate",
    PolicyNumber: "policynumber",
    CancellationDate: "cancellationeffectivedate",
    CancellationType: "cancellationtype",
    CancellationReason: "reason",
    CancellationRequestedBy: "requestedby",
    CancellationRemark: "cancellationterms",
    CancellationRejected: "CancellationRejected",
    Cancellationmep: "cancellationmep",
    Inspectionfee: "inspectionfee",
    PolicyFee: "policyfee"
  },
  buttonStatus: {
    showCalculateBtn: true,
    cancellationcard: false,
    showOnRejectBtn: false,
    showOnHoldBtn: false,
    showDownloadFormBtn: false,
    showRequestToCancelationBtn: false,
    showAdjustPremiumBtn: false,
    showApproveCancelationBtn: false,
    showSignature: false,
    showSendSignatureBtn: false,
    showDownloadSignatureBtn: false,
    showDocumentsCard: false,
    showExitBtn: false,
    showActionButton: true,
    showRevertBtn: true,
    showSubmitForApprovalBtn: false,
    showBtnCenter: false,
    showSubmitSignature: false,
    showOverrideFees: false,
    showOverrideReturnPremium: false
  },
  cancellationCardConfig: {
    title: "Return Premium Information",
    withoutFeesTxt: "Return Premium Amount (without fees & Taxes)",
    feesAndTaxesTxt: "Return Fees & Taxes",
    withFeesTxt: "Return Premium With Fees & Taxes",
    isSplitOverlay: true,
    currentBalanceTxt: "Current Policy Balance",
    returnBalanceDueTxt: "Estimated Return/Balance Due"
  }
}

const reinstatementKeys = {
  schemaKeys: {
    BusinessName: "businessname",
    MailingAddress: "mailingaddress",
    LOB: "lineOfBusiness",
    EffectiveDate: "policyeffectivedate",
    ExpiryDate: "policyenddate",
    PolicyNumber: "policynumber",
    ReinstatementDate: "EffectiveDate",
    RequestedBy: "requestedby",
    RequestReason: "reason",
    RequestTerms: "reinstateterms",
    Inspectionfee: "inspectionfee",
    PolicyFee: "policyfee"
  },
  buttonStatus: {
    showCalculateBtn: true,
    reinstatementcard: false,
    showSignature: false,
    showSubmitForApprovalBtn: false,
    showActionButton: true,
    showOnRejectBtn: false,
    showOnHoldBtn: false,
    showApproveBtn: true,
    showDownloadFormBtn: true,
    showRequestToReinstatementBtn: false,
    showExitBtn: false,
    showRevertBtn: true,
    showBtnCenter: false,
    showSubmitSignature: false
  },
  reinstatementCardConfig: {
    title: "Additional Premium Information",
    withoutFeesTxt: "Additional Premium Amount (without fees & Taxes)",
    feesAndTaxesTxt: "Additional Fees and Taxes",
    withFeesTxt: "Additional Premium With Fees & Taxes",
    isSplitOverlay: true
  }
}

const PolicyLogHeader = {
  PolicyHolder: "Policy Holder Information",
  PropertyCharacteristics: "Property Characteristics",
  UnderwritingQuestion: "Underwriting Question",
  MortgageeDetails: "Mortgagee Details",
  AdditionalInsured: "Additional Insured",
  AdditionalInterest: "Additional Interest",
  CoverageAndLimits: "Coverage & Limit Options",
  PayDetails: "Pay Details",
  ScheduledPersonalProperty: "Scheduled Personal Property",
  GolfCartCoverage: "Golf Cart Coverage",
  DogLiabilityDetails: "Dog Liability Details"
}

const DMS_AllowedFiles = [
  "jpeg",
  "jpg",
  "png",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "txt",
  "pdf",
  "pptx",
  "ppt",
  "csv"
]
const ignoreFileView = ["txt", "csv"]

const LicenseKeys = {
  Syncfusion:
    "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Td0NjX3pecHFWQ2ZY"
}

const notificationKeys = {
  BindRequestAwaitingUWApproval: "/Application/BindRequestAwaitingUWApproval",
  BindRequestDeclinedbyUW: "/Application/BindRequestDeclinedbyUW", // UW declined bind request by Agent
  BindRequestonHold: "/Application/BindRequestonHold", // UW put policy in application on Hold coz some documents or something
  OnDeclineReferral: "/Application/OnDeclineReferral",
  onHoldPolicy: "/Application/onHoldPolicy",
  sendDocument: "/Application/sendDocument",
  paymentSuccess: "/Application/paymentSuccess",
  sendEmails: "/Application/sendEmails",
  coverages: "/Application/coverages",
  SignDocuments: "/Application/SignDocuments",
  ReferralNotification: "/Application/ReferralNotification"
}

const businessInfoKeys = {
  EffectiveDate: "effectivedatecoverage",
  EffectiveDateProp: "EffectiveDate",
  IncorporateDateProp: "InsuredAccount.BusinessInfo.IncorporationDate",
  IncorporateDate: "incorporationDate",
  AgentDetails: "agentdetails",
  UnderwriterDetails: "underwriterdetails",
  AutoHistory: "autohistory",
  ClaimsInLast3Years: "claimsinlast3years",
  Name: "Name",
  Email: "InsuredAccount.Communications.1.Value",
  PreviousPoliciesIsPreviousPolicy: "PreviousPolicies.IsPreviousPolicy",
  PreviousPoliciesPolicyEffectiveDate: "PreviousPolicies.PolicyEffectiveDate",
  PreviousPoliciesPolicyExpiryDate: "PreviousPolicies.PolicyExpiryDate",
  PreviousPoliciesCoverages: "PreviousPolicies.Coverages",
  InsuredAccountBusinessInfoMoveMidTerm:
    "InsuredAccount.BusinessInfo.MoveMidTerm",
  InsuredAccountBusinessInfoPolicyDeclineLastThreeYears:
    "InsuredAccount.BusinessInfo.PolicyDecline.LastThreeYears",
  InsuredAccountBusinessInfoIncorporationDate:
    "InsuredAccount.BusinessInfo.IncorporationDate",
  InsuredAccountBusinessInfoLocations: "InsuredAccount.BusinessInfo.Locations",
  InsuredAccountBusinessInfoCommodities:
    "InsuredAccount.BusinessInfo.Commodities",
  InsuredAccountBusinessInfoRadiusOfOperation:
    "InsuredAccount.BusinessInfo.OperationDetails.RadiusofOperation",
  RadiusOfOperation: "radiusofOperation",
  TypeOfOperation: "typeofOperation",
  PercentageOfOperation: "percentageofoperation",
  StatesOfOperation: "statesofOperation",
  YearOfIncorporation: "yearofincorporation",
  BusinessType: "businessType",
  CoveragesProvided: "coveragesProvided",
  Commodity: "Commodity",
  SelectRetailAgency: "retailagencyselect",
  SelectAgent: "agentselect",
  SelectUW: "underwriterselect",
  ExistingPolicyEffectiveDate: "existingPolicyEffectiveDate",
  ExistingPolicyExpirationDate: "existingPolicyExpirationDate",
  PercentageHauled: "PercentageHauled",
  SMSAlerts: "smsAlerts",
  BusinessName: "businessName",
  DBAName: "dbaName",
  IsOrHaveSubsidiaries: "isOrHaveSubsidiaries",
  IsSubsidiaryCompanyName: "isSubsidiaryCompanyName",
  IsSubsidiaryDescription: "isSubsidiaryDescription",
  HaveSubsidiaryCompanyName: "haveSubsidiaryCompanyName",
  HaveSubsidiaryDescription: "haveSubsidiaryDescription",
  IsSubsidiaryPercentage: "isSubsidiaryPercentage",
  HaveSubsidiaryPercentage: "haveSubsidiaryPercentage",
  TravellingOutofState:
    "InsuredAccount.BusinessInfo.OperationDetails.TravellingOutofState",
  StatesofOperationPath:
    "InsuredAccount.BusinessInfo.OperationDetails.AreasofOperations",
  AgencyName: "Agency.Name",
  AgentName: "Agent.Name",
  UnderWriterName: "UnderWriter.Name",
  IsPastOwnershipChanged:
    "InsuredAccount.BusinessInfo.InPastOwnershipChanged.Value",
  IsOrHaveSubsidiariesPath: "InsuredAccount.BusinessInfo.SubsidiaryInfo",
  BusinessNamePath: "InsuredAccount.BusinessInfo.BusinessName",
  PhoneNumberPath: "InsuredAccount.Communications.0.Value",
  CurrentInsurancePolicyInfo: "currentInsurancePolicyInfo",
  IsInsuredLookMoveMidTerm: "isinsuredlookmovemidterm",
  PolicyDeclineThreeYears: "policydeclinthreeyears",
  IsPastOwnershipChangedKey: "isPastOwnershipChanged",
  Subsidiary: "Subsidiary",
  IsaSubsidiary: "Is a Subsidiary",
  TravellingOutofStateHowOften: "travellingOutofStatehowoften",
  IsSubsidiaryPercentagePath:
    "InsuredAccount.BusinessInfo.IsSubsidiary.PercentageOwned",
  HaveSubsidiaryPercentagePath:
    "InsuredAccount.BusinessInfo.HaveSubsidiary.PercentageOwned",
  UnderwriterSelect: "underwriterselect",
  Percentage: "Percentage",
  IncorporationDate: "IncorporationDate",
  OperationHistory: "InsuredAccount.BusinessInfo.Operations.History",
  LossHistory: "InsuredAccount.BusinessInfo.LossHistory",
  AdditionalInsured: "InsuredAccount.AdditionalInsured"
}

export {
  notificationKeys,
  summaryActionButtonsKeys,
  UserFeaturesKeys,
  payOptionsKeys,
  dateKeys,
  UnderwritingKeys,
  FeesAndTaxesKeys,
  cancellationKeys,
  reinstatementKeys,
  PolicyLogHeader,
  DMS_AllowedFiles,
  ignoreFileView,
  LicenseKeys,
  flowKeys,
  ModalSchemaKeys,
  storeKeys,
  apolloKeys,
  commonKeys,
  axiosKeys,
  tabKeys,
  layoutKeys,
  templateKeys,
  requestKeys,
  businessInfoKeys
}
