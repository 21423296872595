import { logAPIErrorData } from "@cogitate/ui-utils-core-test"
import { consoleLogs } from "@cogitate/ui-utils-core-test/dist/data/constants/messages"
import { apolloKeys, axiosKeys, storeKeys } from "ui/data/constants/keys"
import { GenerateDocument } from "@cogitate/ui-utils-core-test/dist/utilities/apollo/operations"
import { fetchFormFactory } from "@cogitate/ui-utils-core-test/dist/utilities/pages/application"
import {
  getStoreState,
  logoutUser,
  triggerNotification
} from "@cogitate/ui-utils-core-test/dist/utilities/pages/shared"
import { loadDataFromStorage } from "@cogitate/ui-utils-core-test/dist/utilities/shared/schemafromblob"
import jsonata from "jsonata"
import NProgress from "nprogress"
import { commonKeys } from "ui/data/constants/keys"
import {
  PolicyStatusMaster,
  TransactionTypeMaster
} from "ui/data/constants/status"
import { policySchemaKeys } from "ui/data/personal/homeowners/constants/keys"
import moment from "moment"
import {
  addDateFormatting,
  callAPI
} from "@cogitate/ui-utils-core-test/dist/utilities/shared/helpers"
import { GRAPHQL_QUERIES } from "@cogitate/ui-utils-core-test/dist/utilities/apollo/queries"
import {
  ExecuteQuery,
  GetApolloClient
} from "@cogitate/ui-utils-core-test/dist/utilities/apollo/client"
import { toast } from "react-toastify"
import { alertMsgs } from "ui/data/personal/homeowners/constants/messages"

export const showHidePremiumHelper = (
  summary,
  versionPolicy,
  loggedInUserDetails
) => {
  let _showPremium = true
  if (
    [
      PolicyStatusMaster.SubmissionDeclined,
      PolicyStatusMaster.CancellationInitiated,
      PolicyStatusMaster.ReInstatementInitiated,
      PolicyStatusMaster.PolicyInForceCancellationRequestPendingApproval
    ].includes(summary.PolicyStatus)
  ) {
    _showPremium = false
  }
  if ([commonKeys.RoleAgent].includes(loggedInUserDetails?.decodedJWT?.role)) {
    if (
      [
        PolicyStatusMaster.ReferralAwaitingUWReview,
        PolicyStatusMaster.QuoteIndication,
        PolicyStatusMaster.Submission,
        PolicyStatusMaster.SubmissionDeclined,
        PolicyStatusMaster.SubmissionDeclinedByUW
      ].includes(summary.PolicyStatus) &&
      summary.Rules.Action != "Allow"
    ) {
      _showPremium = false
    }
  }
  if (
    [
      PolicyStatusMaster.EndorsementInitiated,
      PolicyStatusMaster.EndorsementRequestAwaitingUWApproval
    ].includes(versionPolicy.PolicyStatus)
  ) {
    _showPremium = false
  }

  // if (!summary.Attributes.IsCoverageSaved) {
  //   _showPremium = false;
  // }
  return _showPremium
}

export const showHideDownloadRateHelper = async (
  summary,
  versionPolicy,
  loggedInUserDetails
) => {
  let _showDownloadRate = true
  if ([PolicyStatusMaster.SubmissionDeclined].includes(summary.PolicyStatus)) {
    _showDownloadRate = false
  } else if (
    [commonKeys.RoleAgent].includes(loggedInUserDetails?.decodedJWT?.role)
  ) {
    _showDownloadRate = false
  } else if (!summary.Attributes.IsCoverageSaved) {
    _showDownloadRate = false
  } else if (summary.Transaction.Number > 0) {
    _showDownloadRate = false
  }
  if (
    [
      PolicyStatusMaster.EndorsementInitiated,
      PolicyStatusMaster.EndorsementRequestAwaitingUWApproval
    ].includes(versionPolicy.PolicyStatus)
  ) {
    _showDownloadRate = false
  }

  return _showDownloadRate
}

export const showStatusHelper = (summary, versionPolicy) => {
  let status = summary.PolicyStatus
  let remark = summary.PolicyStatusRemarks
  if (
    [
      PolicyStatusMaster.EndorsementInitiated,
      PolicyStatusMaster.EndorsementRequestAwaitingUWApproval
    ].includes(versionPolicy.PolicyStatus)
  ) {
    status = versionPolicy.PolicyStatus
    remark = versionPolicy.PolicyStatusRemarks
  }
  return { status, remark }
}

export const downloadeRateSheet = async (summary) => {
  let templateName
  switch (summary?.Attributes?.State) {
    case "FL":
      templateName = commonKeys.TemplateFLRateSheet
      break
    default:
      templateName = commonKeys.TemplateFLRateSheet
      break
  }
  NProgress.start()
  const printresponse = await triggerNotification(
    commonKeys.ActionDownloadRatesheet,
    commonKeys.NotificationFormatPDF,
    templateName,
    summary,
    false,
    {},
    false
  )
  const url = window.URL.createObjectURL(printresponse)
  var dlnk = document.getElementById("ratedownload")
  dlnk.href = url
  dlnk.setAttribute("download", `${summary.QuoteNumber}_Rate_Sheet.pdf`)
  dlnk.click()
  NProgress.done()
}

export const getForms = async (summary) => {
  let forms = []
  if (summary.Forms.length == 0) {
    const formList = await fetchFormFactory(summary)
    const defaultForms = formList?.filter(
      (x) => x.IsMandatory == true || x.IsChecked == true
    )
    defaultForms?.forEach((form) => {
      forms.push({
        Status: "",
        FormName: form.formName,
        FormDesc: form.formDesc,
        Sequence: form.sequence,
        FormType: form.formType,
        Template: form.template,
        IsMandatory: form.IsMandatory,
        IsChecked: form.IsChecked,
        AcordCode: form.AcordCode,
        File: "",
        Dmspath: ""
      })
    })
  } else {
    forms = summary.Forms
  }
  return forms
}

// Lob param to have lob specific checks
export const checkActionDisplay = (button, role, status) => {
  const getRole = getStoreState(storeKeys.DataReducer).roleAuthorization
  const versionPolicy = getStoreState(storeKeys.VersionReducer)
  if (
    [
      PolicyStatusMaster.EndorsementInitiated,
      PolicyStatusMaster.EndorsementRequestAwaitingUWApproval
    ].includes(versionPolicy.PolicyStatus)
  ) {
    status = versionPolicy.PolicyStatus
  }

  try {
    if (getRole[role]?.[status]) {
      if (getRole[role]?.[status].includes(button)) {
        return true
      }
    } else {
      false
    }
  } catch (err) {
    console.log("err", err)
  }
}

export const getUserList = (agents) => {
  const newArray = []
  for (const item of agents) {
    const obj = {}
    obj.name = item?.Name
    obj.userId = item?.Code
    obj.email = item?.Details?.Contact?.EmailId
    newArray.push(obj)
  }
  return newArray
}

export const replacePlaceholderWithObject = (data, replaceData) => {
  let dataStr = JSON.stringify(data)
  replaceData.forEach((item) => {
    dataStr = dataStr.replaceAll(item.key, JSON.stringify(item.value))
  })
  return JSON.parse(dataStr)
}

export const getModelSchema = (flowKeys, routeKeys, replaceData) => {
  const schemaData = loadDataFromStorage(
    "",
    process.env.NEXT_PUBLIC_DEFAULT_LINE,
    process.env.NEXT_PUBLIC_LINE_OF_BUSINESS,
    process.env.NEXT_PUBLIC_DEFAULT_STATE?.toLowerCase(),
    process.env.NEXT_PUBLIC_DEFAULT_CARRIER?.toLowerCase(),
    process.env.NEXT_PUBLIC_DEFAULT_VERSION?.toLowerCase(),
    flowKeys.toLowerCase(),
    routeKeys,
    -1
  )
  if (replaceData) {
    return replacePlaceholderWithObject(schemaData, replaceData)
  }
  return schemaData
}

export const statusOptions = (PolicyStatusMaster) => {
  var optionArray = []
  Object.entries(PolicyStatusMaster).forEach(([KEY, VALUE]) => {
    var option = {
      key: KEY,
      label: VALUE,
      value: VALUE
    }
    optionArray.push(option)
  })
  optionArray.sort((a, b) => (a.label > b.label ? 1 : -1))
  optionArray.unshift({
    key: "SELECT",
    label: "All Status",
    value: ""
  })
  return optionArray
}

export const staticOptions = (staticItems, key) => {
  var optionArray = []
  if (key == "AllowedStates" || key == "LOBList") {
    const selected = staticItems[`${key}`]
    Object.entries(selected).forEach(([KEY, VALUE]) => {
      var option = {
        key: KEY,
        label: VALUE,
        value: VALUE
      }
      optionArray.push(option)
    })
  }
  return optionArray
}

export const uwOptions = (key) => {
  var optionArray = []
  const currentMasters = getStoreState(storeKeys.MasterReducer) || []
  currentMasters[key]?.forEach((u) => {
    optionArray.push({ key: u.Code, label: u.Name, value: u.Code })
  })
  optionArray.sort((a, b) => (a.label > b.label ? 1 : -1))
  return optionArray
}

export const getLobFromSchemaData = (schemaData) => {
  try {
    return schemaData[0].lob
  } catch (err) {
    return "personal/homeowners" // TODO: temporary safe default value
  }
}

export const downloadDraft = async (formsFactory, summary) => {
  NProgress.start()
  const tempSummary = addRequiredForms(
    formsFactory["Required Forms"], // Add this if optional forms required
    formsFactory["Optional Forms"],
    summary
  )

  const formsResponse = await GenerateDocument(tempSummary, true, false)
  if (formsResponse) {
    var blob = new Blob([formsResponse], { type: "application/pdf" })
    var link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    var fileName = `${summary.QuoteNumber}_Draft_Forms`
    link.download = fileName
    link.click()
  }
  NProgress.done()
}

export const addRequiredForms = (mandatoryForms, optionalForms, policy) => {
  try {
    const tempSummary = JSON.parse(JSON.stringify(policy))
    tempSummary.Forms = []
    mandatoryForms
      ?.filter((x) => x.IsMandatory == true || x.IsChecked == true)
      ?.forEach((form) => {
        tempSummary.Forms.push({
          Status: "",
          FormName: form.formName,
          FormDesc: form.formDesc,
          Sequence: form.sequence,
          FormType: form.formType,
          Template: form.template,
          IsMandatory: form.IsMandatory,
          IsChecked: form.IsChecked,
          AcordCode: form.AcordCode,
          File: "",
          Dmspath: "",
          BlobLocation: form.BlobLocation
        })
      })
    optionalForms
      ?.filter((x) => x.IsMandatory == true || x.IsChecked == true)
      ?.forEach((form) => {
        tempSummary.Forms.push({
          Status: "",
          FormName: form.formName,
          FormDesc: form.formDesc,
          Sequence: form.sequence,
          FormType: form.formType,
          Template: form.template,
          IsMandatory: form.IsMandatory,
          IsChecked: form.IsChecked,
          AcordCode: form.AcordCode,
          File: "",
          Dmspath: "",
          BlobLocation: form.BlobLocation
        })
      })
    return tempSummary
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const validEmailCheck = (email) => {
  // Regular expression for basic email validation
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/
  return !emailRegex.test(email)
}

export const hasFeatureSelector = (state, key) => {
  const tableList = getStoreState(storeKeys.DataReducer)?.roleAuthorization
    ?.Integrations
  return state.masterReducer[tableList[key].tableName].find(
    (item) => item[tableList[key].keyName] === tableList[key].value
  ).Enabled
}

/**Method to transform the list of policies into dashboard specific objects
 * @param {[policies]} resp - list of polcies
 * @param {boolean} IsRenew - flag to tag whether the process records is for renewals or account hub
 * @returns {[transformedPolicies]} - list of transformed policies
 */
export const ProcessAccountHubData = async (resp, IsRenew = false) => {
  try {
    if (resp?.length > 0) {
      const data = resp.map((m) => {
        const pga = m?.Risks?.Properties.find((f) => f.UnitNumber == "1")
        const dispAddress =
          pga?.Address?.UnFormattedAddress ||
          pga?.Address?.AddressLine1 +
            ", " +
            pga?.Address?.City +
            ", " +
            pga?.Address?.State +
            ", " +
            pga?.Address?.CountyCode +
            ", " +
            pga?.Address?.Zip

        return {
          Agent: m.Agent?.Name,
          BusinessName: m.InsuredAccount?.BusinessInfo?.BusinessName,
          InsuredName: m.InsuredAccount?.DisplayName,
          EffectiveDate: m.EffectiveDate,
          ExpirationDate: m.ExpirationDate,
          IsQuoteNumber: m.PolicyNumber.length <= 0,
          IsRenew: IsRenew,
          LastUpdatedDate:
            m.Audit?.LastUpdatedOn == null
              ? m.Audit?.CreatedOn
              : m.Audit?.LastUpdatedOn,
          Location: dispAddress,
          PolicyNumber:
            m.PolicyNumber != "" ? m.PolicyNumber : m.QuoteNumber || "",
          QuoteNumber: m.QuoteNumber != "" ? m.QuoteNumber : "",
          Status: m.PolicyStatus,
          TransactionDate: m.Transaction?.Date,
          Underwriter: m?.UnderWriter?.Name,
          IsMaster: m.Attributes?.IsMasterPolicy,
          ExternalRefrences: m.ExternalRefrences,
          LOB: m.Attributes?.Lob,
          Premium: m.TotalPremium?.AnnualPremium
        }
      })
      data.sort((a, b) => {
        if (new Date(a.LastUpdatedDate) > new Date(b.LastUpdatedDate)) return -1
        else return 1
      })
      return data
    } else return []
  } catch (error) {
    console.log(error)
    logAPIErrorData(
      error,
      { resp, IsRenew },
      consoleLogs.Utilities.Apollo.Operations.ProcessAccountHubData
    )
  }
}

const validateJSONata = async (expression, data) => {
  expression = jsonata(expression)
  const result = await expression.evaluate(data)
  return result
}

const getValueFromStatetByPath = (path, policyData) => {
  return path?.split(".").reduce(
    (o, key) =>
      o && o[key]
        ? // ?.toString()
          o[key]
        : null,
    policyData
  )
}

export const validate = async (validator, policyData) => {
  const errors = {}
  const validators = validator
  let gotError = false

  for (const component of validators) {
    const fieldValue = getValueFromStatetByPath(component.key)

    for (const condition of component.validations) {
      let r
      if (condition.expression)
        r = await validateJSONata(condition.expression, policyData)
      else r = condition.validator(fieldValue, policyData)

      if (!r && !gotError) {
        if (errors[component.key] == undefined) {
          errors[component.key] = []
        }
        errors[component.key].push(condition.message)
        gotError = true
      }
    }
  }
  return errors
}
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
export const setSchemaOnChange = async (schema, key, value, model) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  const LoginUserRole = getUserRole()
  const changedSchema = schema.map((e) => {
    e.controls = e.controls.map((c) => {
      if (c.key == key && c.key == policySchemaKeys.AgentDetails) {
        const option =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.agents
                .filter((a) => a.Reference.includes(value) && a.Role == "Agent")
                .map((a) => {
                  return {
                    key: a.Code,
                    label: a.Name,
                    value: a.Code
                  }
                })
        c.options = getUniqueObj("value", option)
      } else if (c.key == key && c.key == policySchemaKeys.Builder) {
        const option =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.agents
                .filter(
                  (a) => a.Reference.includes(value) && a.Role == "Builder"
                )
                .map((a) => {
                  return {
                    key: a.Code,
                    label: a.Name,
                    value: a.Code
                  }
                })
        c.options = getUniqueObj("value", option)
      } else if (c.key == key && c.key == policySchemaKeys.Community) {
        const option =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.CommunityModelAddress.filter(
                (a) => a.BuilderID == value
              ).map((a) => {
                return {
                  key: a.Community.replace(/\'/g, ""),
                  label: a.Community,
                  value: a.Community.replace(/\'/g, "")
                }
              })
        c.options = getUniqueObj("value", option)
      } else if (c.key == key && c.key == policySchemaKeys.ModelPlan) {
        const option =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.CommunityModelAddress.filter(
                (a) =>
                  a.Community.replace(/\'/g, "") == value.replace(/\'/g, "")
              ).map((a) => {
                return {
                  key: a.Model.replace(/\'/g, ""),
                  label: a.Model,
                  value: a.Model.replace(/\'/g, "")
                }
              })
        option.push({
          key: "Other",
          label: "Other",
          value: "Other"
        })

        c.options = getUniqueObj("value", option)
      } else if (c.key == key && c.key == policySchemaKeys.PropertyAddress) {
        const option =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.CommunityModelAddress.filter(
                (a) =>
                  a.Model.replace(/\'/g, "") == value.replace(/\'/g, "") &&
                  a.BuilderID == model.Risks.Properties[0].Builder.Code &&
                  a.Community.replace(/\'/g, "") ==
                    model.Risks.Properties[0].Community.replace(/\'/g, "")
              ).map((a) => {
                return {
                  key: a.Street + ", " + a.City + ", " + a.State + ", " + a.Zip,
                  label:
                    a.Street + ", " + a.City + ", " + a.State + ", " + a.Zip,
                  value:
                    a.Street + ", " + a.City + ", " + a.State + ", " + a.Zip
                }
              })
        c.options = getUniqueObj("value", option)
      } else if (c.key == key && c.key == policySchemaKeys.setPropertyAddress) {
        const address =
          Masters === null || Masters === void 0
            ? void 0
            : Masters.CommunityModelAddress.filter((a) => a.Model == value)
        console.log("address", address)
        //c.options = option;
      } else if (c.key == key && c.key == policySchemaKeys.RelationToPrimary) {
        if (model.InsuredAccount.OtherDetails.MaritalStatus == "Married") {
          model.JointPolicyHolders[0].OtherDetails.RelationToPrimary = "Spouse"
          c.props.disabled = true
        } else {
          model.JointPolicyHolders[0].OtherDetails.RelationToPrimary = "Other"
          c.props.disabled = false
        }
      } else if (c.key == key && c.key == policySchemaKeys.MaritalStatus) {
        if (model.InsuredAccount.HasJointPolicyHolder == "false") {
          c.props.disabled = false
        } else {
          if (
            model.JointPolicyHolders[0].OtherDetails.RelationToPrimary ==
            "Spouse"
          ) {
            c.props.disabled = true
          } else {
            model.InsuredAccount.OtherDetails.MaritalStatus = ""
            c.props.disabled = false
          }
        }
      } else if (
        c.key == key &&
        (c.key == policySchemaKeys.EditEffectiveDate ||
          c.key == policySchemaKeys.PolicyEffectiveDate)
      ) {
        if (model.PolicyStatus == PolicyStatusMaster.QuoteOffered) {
          c.props.minDate = moment().format("YYYY-MM-DD")
          if (LoginUserRole.includes("Underwriter")) {
            c.props.minDate = moment().add(-30, "days").format("YYYY-MM-DD")
          }
          c.props.maxDate = moment().add(60, "days").format("YYYY-MM-DD")
        } else {
          var daysDiff = moment(model.EffectiveDate).diff(moment(), "days")
          if (daysDiff > 7) {
            c.props.minDate = moment().format("YYYY-MM-DD")
          } else {
            c.props.minDate = moment(model.EffectiveDate)
              .add(-7, "days")
              .format("YYYY-MM-DD")
          }
          c.props.maxDate = moment(model.EffectiveDate)
            .add(60, "days")
            .format("YYYY-MM-DD")
        }
      } else if (c.key == key && c.key == policySchemaKeys.RoofType) {
        if (value == "Flat") {
          c.props.disabled = true
        } else {
          c.props.disabled = false
        }
      } else if (c.key == key && c.key == policySchemaKeys.RoofCover) {
        if (value.includes("Tile")) {
          c.props.disabled = true
        } else {
          c.props.disabled = false
        }
      } else if (c.key == key && c.key == policySchemaKeys.Payplan) {
        if (value == "Mortgagee Bill") {
          c.options = [
            {
              key: "escrow",
              label: "Escrow",
              value: "Escrow"
            },
            {
              key: "payinfull",
              label: "Pay in full",
              value: "Pay in full"
            },
            {
              key: "monthly",
              label: "Monthly",
              value: "Monthly"
            },
            {
              key: "quarterly",
              label: "Quarterly",
              value: "Quarterly"
            },
            {
              key: "semiannual",
              label: "Semi-Annual",
              value: "Semi-Annual"
            }
          ]
        } else {
          c.options = [
            {
              key: "payinfull",
              label: "Pay in full",
              value: "Pay in full"
            },
            {
              key: "monthly",
              label: "Monthly",
              value: "Monthly"
            },
            {
              key: "quarterly",
              label: "Quarterly",
              value: "Quarterly"
            },
            {
              key: "semiannual",
              label: "Semi-Annual",
              value: "Semi-Annual"
            }
          ]
        }
      }
      return c
    })
    return e
  })
  return changedSchema
}
export const setAgencyData = async (PolicyModel) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  PolicyModel.Agency =
    Masters === null || Masters === void 0
      ? void 0
      : Masters.agencies.filter((a) => a.Code == PolicyModel.Agency.Code)[0]
  return PolicyModel
}
export const setAgentData = async (PolicyModel) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  PolicyModel.Agent =
    Masters === null || Masters === void 0
      ? void 0
      : Masters.agents.filter((a) => a.Code == PolicyModel.Agent.Code)[0]
  return PolicyModel
}
export const setBuilderData = async (PolicyModel) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  const builderData =
    Masters === null || Masters === void 0
      ? void 0
      : Masters.agents.filter(
          (a) => a.Code == PolicyModel.Risks.Properties[0].Builder.Code
        )[0]
  PolicyModel.Risks.Properties[0].Builder.Name = builderData.Name
  return PolicyModel
}
export const setSchemaOnAddressChange = async (PolicyModel, isReset) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  const address =
    PolicyModel.Risks.Properties[0].Address.UnFormattedAddress.split(", ")
  const CommunityModelData =
    Masters === null || Masters === void 0
      ? void 0
      : Masters.CommunityModelAddress.filter(
          (a) =>
            a.Model == PolicyModel.Risks.Properties[0].ModelPlan &&
            a.Street == address[0]
        )[0]
  const changedModel = PolicyModel
  if (isReset == false) {
    changedModel.Risks.Properties[0].Address["AddressLine1"] =
      CommunityModelData["Street"]
    changedModel.Risks.Properties[0].Address["StreetName"] =
      CommunityModelData["Street"]
    changedModel.Risks.Properties[0].Address["City"] =
      CommunityModelData["City"]
    changedModel.Risks.Properties[0].Address["State"] =
      CommunityModelData["State"]
    changedModel.Risks.Properties[0].Address["Zip"] =
      "" + CommunityModelData["Zip"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["PropertyType"] =
      CommunityModelData["Property Type"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["OccupancyType"] =
      CommunityModelData["Occupancy Type"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["YearBuilt"] =
      CommunityModelData["Year Built"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["SquareFeet"] =
      CommunityModelData["Total Living Area (Sq Ft)"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["NoOfStories"] =
      CommunityModelData["Stories"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["NoOfBedrooms"] =
      CommunityModelData["Bedrooms"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["NoOfBaths"] =
      CommunityModelData["Baths"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes[
      "ConstructionType"
    ] = CommunityModelData["Construction Type"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["RoofType"] =
      CommunityModelData["Roof Type"]
    changedModel.Risks.Properties[0].PropertyRiskAttributes["RoofShape"] =
      CommunityModelData["Roof Shape"]
    changedModel.Risks.Properties[0].Coverages[0].Value =
      CommunityModelData["Dwelling (Coverage A)"]
  } else {
    if (
      changedModel.Risks.Properties[0].PropertyRiskAttributes.IsManualAddress ==
      "false"
    ) {
      changedModel.Risks.Properties[0].Address.UnFormattedAddress = ""
    } else {
      changedModel.Risks.Properties[0].Address["AddressLine1"] = ""
      changedModel.Risks.Properties[0].Address["AddressLine2"] = ""
      changedModel.Risks.Properties[0].Address["StreetName"] = ""
      changedModel.Risks.Properties[0].Address["City"] = ""
      changedModel.Risks.Properties[0].Address["State"] = ""
      changedModel.Risks.Properties[0].Address["Zip"] = ""
    }
  }
  return changedModel
}

export const checkValidAddress = (policy) => {
  const addrResp = {
    success: false,
    errorFields: []
  }

  const Addr = policy?.Risks?.Properties[0]?.Address
  const riskAttr = policy?.Risks?.Properties[0]?.PropertyRiskAttributes

  const obj = {
    policy,
    Latitude: Addr?.Lat,
    Longitude: Addr?.Long,
    DistanceToCoast: riskAttr?.DistanceToCoast,
    BCEGID: riskAttr?.BCEGID,
    CensusBlockGroup: riskAttr?.CensusBlockGroup,
    RespondingFireDistrictName: riskAttr?.RespondingFireDistrictName,
    RespondingFireDistrictId: riskAttr?.RespondingFireDistrictId,
    WindRegion: riskAttr?.WindRegion,
    WindSpeedRegion: riskAttr?.WindSpeedRegion0,
    WindTerrain: riskAttr?.WindTerrain,
    RoofDeck: riskAttr?.RoofDeck
  }

  for (const field in obj) {
    if (
      obj[field] === "" ||
      obj[field] === 0 ||
      obj[field] === null ||
      obj[field] === undefined ||
      obj[field] === "unknown"
    ) {
      addrResp.success = true
      addrResp.errorFields.push(field)
    }
  }

  return addrResp
}

export const fetchInternalAPI = (policyData) => {
  const Masters = getStoreState(storeKeys.MasterReducer) || []
  if (policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGID != "") {
    const bcegId = policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGID
    const option =
      Masters === null || Masters === void 0
        ? void 0
        : Masters.Bceg.filter((a) => a.bcegId == bcegId)
    const sortedData = option.sort((a, b) => (a.BEGIN_YR < b.BEGIN_YR ? 1 : -1))
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.BCEGNumber =
      sortedData.length > 0 ? sortedData[0].BCEG : 0
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.BeginYear =
      sortedData.length > 0 ? sortedData[0].BEGIN_YR : 0
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.CommunityName =
      sortedData.length > 0 ? sortedData[0].COMMUNITY_NAME : ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.County =
      sortedData.length > 0 ? sortedData[0].COUNTY : ""
  } else {
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.BCEGNumber = 0
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.BeginYear = 0
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.CommunityName =
      ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.BCEGDetails.County =
      ""
  }

  if (
    policyData.Risks.Properties[0].PropertyRiskAttributes
      .RespondingFireDistrictId != "" &&
    policyData.Risks.Properties[0].PropertyRiskAttributes
      .RespondingFireDistrictId != "unknown"
  ) {
    const PC =
      policyData.Risks.Properties[0].PropertyRiskAttributes
        .RespondingFireDistrictId
    const option =
      Masters === null || Masters === void 0
        ? void 0
        : Masters.PC.filter((a) => a.respondingFireDistrictId == PC)
    const sortedData = option.sort((a, b) =>
      a["EFFECTIVE DATE"] < b["EFFECTIVE DATE"] ? 1 : -1
    )
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.County =
      sortedData.length > 0 ? sortedData[0].COUNTY : ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.EffectiveDate =
      sortedData.length > 0 ? sortedData[0]["EFFECTIVE DATE"] : ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.Protected =
      sortedData.length > 0 ? "" + sortedData[0].PROTECTED : ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.UnProtected =
      sortedData.length > 0 ? "" + sortedData[0].UNPROTECTED : ""
  } else {
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.County =
      ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.EffectiveDate =
      ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.Protected =
      ""
    policyData.Risks.Properties[0].PropertyRiskAttributes.ProtectionClass.UnProtected =
      ""
  }
  return policyData
}

const isAgent = () => {
  const loggedInReducer = getStoreState(
    storeKeys.LoggedInUserReducer
  )?.decodedJWT
  const role = loggedInReducer?.role
  const agentCode = loggedInReducer?.UserId
  if (role != "UW" && role != "Underwriter") {
    return {
      success: true,
      agentCode: agentCode
    }
  } else
    return {
      success: false,
      agentCode: null
    }
}

const defaultInputClause = {
  input: {
    clause: [{ src: "1", def: "1", exp: "=", operator: "" }],
    containerName: "policy",
    LIMIT: 100
  }
}

const dbKeys = {
  policystatus: "r.PolicyStatus",
  agentid: "r.Agent.Code",
  agentname: "r.Agent.Name",
  agencyid: "r.Agency.Code",
  agencyname: "r.Agency.Name",
  underwriterid: "r.UnderWriter.Code",
  fromdate: "r.EffectiveDate",
  todate: "r.EffectiveDate",
  transactiondate: "r.Transaction.Date",
  quoteNumber: "r.QuoteNumber",
  policynumber: "r.PolicyNumber",
  insuredname: "r.InsuredAccount.DisplayName",
  propertyaddress: "r.Risks.Properties.0.Address.UnFormattedAddress",
  state: "r.Attributes.State",
  lob: "r.Attributes.Lob"
}

const getExpression = (key) => {
  if (key === "fromdate") return ">="
  if (key === "todate") return "<="
  if (
    [
      "agencyname",
      "agentname",
      "agentid",
      "underwriterid",
      "lob",
      "insuredname",
      "state",
      "policynumber",
      "quoteNumber"
    ].includes(key)
  )
    return "LIKE"
  return "="
}

const buildClause = (key, value) => {
  const expression = getExpression(key)
  const clause = {
    src: dbKeys[key],
    def: `'${value}'`,
    exp: expression,
    operator: "AND"
  }
  if (
    [
      "agencyname",
      "agentname",
      "agentid",
      "lob",
      "insuredname",
      "state",
      "policynumber",
      "quoteNumber",
      "underwriterid"
    ].includes(key)
  ) {
    clause.src = `UPPER(${dbKeys[key]})`
    clause.def = `'%${value.toUpperCase()}%'`
  }

  return clause
}

const addInitialClauses = (input) => {
  const agentRecord = isAgent()
  if (agentRecord.success) {
    const masterReducer = getStoreState(storeKeys.MasterReducer)
    const agentCode = agentRecord.agentCode
    const agencyCode = masterReducer["agents"]?.find(
      (x) => x.Code === agentCode
    )?.Reference[0]
    input.input.clause.push({
      src: "r.Agency.Code",
      def: "'" + agencyCode + "'",
      exp: "=",
      operator: "AND"
    })
  }

  input.input.clause.push({
    src: "(r.PolicyStatus",
    def: "'Renewal-Offered' OR r.PolicyStatus='Renewal Offer Rejected By UW' OR r.PolicyStatus='Renewal Offer Rejected By Agent' OR r.PolicyStatus='Renewal Submission' OR r.PolicyStatus='Renewal Signature Pending' OR r.PolicyStatus='Renewal-Offered Expired' OR r.PolicyStatus='Renewal Expired' OR r.PolicyStatus='Renewed-Endorsement Pending Approval' OR r.PolicyStatus='Renewed-Endorsement Request on Hold')",
    exp: "!=",
    operator: "AND"
  })

  input.input.clause.push({
    src: "r.Attributes.Client",
    def: `'${process.env.NEXT_PUBLIC_CLIENT}' ORDER BY r.Audit.LastUpdatedOn DESC`,
    exp: "=",
    operator: "AND"
  })
}

export const getInputClause = (initialLoad, accounthubModel) => {
  const input = structuredClone(defaultInputClause)
  if (!initialLoad) {
    Object.keys(accounthubModel).forEach((key) => {
      if (key !== "accountid") {
        if (accounthubModel[key]?.length > 0) {
          input.input.clause.push(buildClause(key, accounthubModel[key]))
        }
      }
    })
  }

  addInitialClauses(input)
  return input
}
export const getUserRole = () => {
  const loginUser = getStoreState(storeKeys.LoggedInUserReducer) || []
  return loginUser?.decodedJWT?.role
}
export const setUserRole = (
  currentPolicyState,
  currentLoginState,
  currentMasterState
) => {
  try {
    if (!currentLoginState) {
      currentLoginState = getStoreState(storeKeys.LoggedInUserReducer) || []
    }
    if (!currentMasterState) {
      currentMasterState = getStoreState(storeKeys.MasterReducer)
    }
    currentPolicyState.AccountId =
      currentLoginState?.decodedJWT?.role.split(",").length > 1
        ? currentLoginState?.decodedJWT?.role.split(",").pop()
        : currentLoginState?.decodedJWT?.role
    if (
      currentPolicyState.AccountId == "Agent" ||
      currentPolicyState.AccountId == "Builder"
    ) {
      if (currentMasterState.static) {
        currentPolicyState.Agent = currentMasterState.agents[0]
      } else {
        currentPolicyState.Agent = currentMasterState.agents?.find(
          (x) => x.Code == currentLoginState.decodedJWT.UserId
        )
      }
      if (
        currentPolicyState.AccountId == "Builder" &&
        currentPolicyState.Agent
      ) {
        currentPolicyState.Risks.Properties[0].Builder.Name =
          currentPolicyState.Agent.Name
        currentPolicyState.Risks.Properties[0].Builder.Code =
          currentPolicyState.Agent.Code
      }
      currentPolicyState.Agency = currentMasterState.agencies?.find(
        (x) => x.Code == currentPolicyState.Agent.Reference[0]
      )
    } else {
      currentPolicyState.UnderWriter = currentMasterState.underwriter?.find(
        (x) => x.Code == currentLoginState.decodedJWT.UserId
      )
    }
    return currentPolicyState
  } catch (error) {
    console.log(consoleLogs.Utilities.Pages.Login.SetUserRole, error)
    throw error
  }
}

export const getUniqueObj = (key, options) => {
  return options.reduce((first, second) => {
    if (!first.some((o) => o[key] === second[key])) {
      first.push(second)
    }
    return first
  }, [])
}
export const getCurrentUserRole = (currentPolicyState, currentLoginState) => {
  const temp = structuredClone(currentPolicyState)
  temp.AccountId =
    currentLoginState?.decodedJWT?.role.split(",").length > 1
      ? currentLoginState?.decodedJWT?.role.split(",").pop()
      : currentLoginState?.decodedJWT?.role
  return temp
}

export const userLogout = () => {
  logoutUser()

  window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}` + "?logout=1"
}

export const SendDocumentOnEmail = async (
  policy,
  key,
  recipient,
  attachmentNames,
  attachmentTemplates,
  body = "",
  bodyTemplate,
  blobUri = "",
  blobName = ""
) => {
  try {
    const queries = getStoreState(storeKeys.QueriesReducer)
    const subject = "Policy Documents"
    const tempBody = bodyTemplate ? "" : body || " "
    const input = {
      input: {
        AttachmentNames: attachmentNames,
        AttachmentTemplates: attachmentTemplates, //attachmentNames, attachmentTemplates [] with same length
        BCC: "",
        Body: tempBody,
        BodyTemplate: bodyTemplate, //either body or bodytemplate
        CC: "",
        PolicyNumber: policy.PolicyNumber,
        QuoteNumber: policy.QuoteNumber,
        Recipient: recipient,
        Sender: "noreply@cogitate.us",
        Subject: subject,
        Type: "E",
        BlobName: blobName,
        BlobUri: blobUri
      }
    }
    const resp = await ExecuteQuery(
      GetApolloClient(process.env.NEXT_PUBLIC_GQL_END_POINT),
      GRAPHQL_QUERIES(queries, "GET_NOTIFICATION_API"),
      input
    )
    let returnResponse = {}
    if (resp) returnResponse = resp.data[apolloKeys.PostToNotificationAPI] ?? []
    return returnResponse
  } catch (error) {
    toast.error(alertMsgs.Shared.SomethingWentWrong)
    //logAPIErrorData(error, { attachmentNames, attachmentTemplates, body, bodyTemplate, notificationType }, consoleLogs.Utilities.Apollo.Operations.GetPushNotificationAPI);
  }
}

export const uploadFilesToDMS = async (
  policy,
  filesToUpload,
  userID,
  base64 = false,
  filename
) => {
  try {
    const infoId = policy.PolicyNumber
      ? policy.PolicyNumber
      : `${policy.QuoteNumber}-${policy.QuoteVersion}`
    const date = moment()
    const tempFilesArray = [...filesToUpload.UploadedFiles]
    const loggedInuser = getStoreState(storeKeys.LoggedInUserReducer)
    const fileName = filename
      ? filename
      : filesToUpload.UploadedFiles[0]?.name ||
        `${infoId}_${policy.Transaction.Type}_${date}.pdf`

    if (tempFilesArray.length > 0) {
      const promises = tempFilesArray.map(async (file) => {
        const metadata = {
          PolicyInfoId: infoId,
          PolicyNumber: policy.PolicyNumber,
          DocumentType: `${filesToUpload.DocumentType}`,
          CarrierCode: policy.Attributes.Carrier, // This and below property is concatenated to form folder name
          CoverholderCode: policy.Attributes.Coverholder,
          Lob: policy.Attributes.Lob,
          State: policy.Attributes.State,
          TransactionNumber: policy.Transaction.Number.toString(),
          TransactionType: policy.Transaction.Type,
          TransactionDate: moment().toDate(),
          UserName: userID
            ? userID
            : `${loggedInuser.decodedJWT.FirstName} ${loggedInuser.decodedJWT.LastName}`,
          Filename: fileName,
          Applicationstatus: policy.PolicyStatus,
          FolderName: filesToUpload.FolderName
        }
        const blobPath = `${policy.Attributes.Carrier}${policy.Attributes.Coverholder}/${policy.Attributes.Lob}/${policy.Attributes.State}/${filesToUpload.PolicyNumber}/${metadata.Filename}`
        const formData = new FormData()
        formData.append("file", file, metadata.Filename)
        formData.append("containerName", process.env.NEXT_PUBLIC_DMS_API_NAME)
        formData.append("blobPath", blobPath)
        formData.append("metadata", JSON.stringify(metadata))
        const apiURL =
          process.env.NEXT_PUBLIC_DMS_API_BASE_URL +
          process.env.NEXT_PUBLIC_DMS_API_POST_UPLOAD_TO_BLOB
        const apiResponse = await callAPI(
          axiosKeys.PostAction,
          apiURL,
          formData,
          {
            Authorization: process.env.NEXT_PUBLIC_DMS_API_AUTH,
            "content-type": "multipart/form-data"
          }
        )
        return apiResponse
      })
      // eslint-disable-next-line
      return await Promise.all(promises).then((resp) => {
        return resp
      })
    }
  } catch (err) {
    console.error(consoleLogs.Utilities.Pages.Application.UploadFilesToDMS, err)
    throw err
  }
}

export const GenerateAndUploadDocument = async (model, IsUpload = true) => {
  const transactionType = "QuoteOffered"
  let formList = []
  const referenceNumber =
    model.PolicyNumber?.length > 0 ? model.PolicyNumber : model.QuoteNumber
  const timestamp = moment().format("MMDDHHmm")

  const refNowithVersion =
    model.PolicyNumber?.length > 0
      ? model.PolicyNumber
      : `${model.QuoteNumber}-${model.QuoteVersion}`
  try {
    const forms = await fetchFormFactory(model, transactionType)

    if (forms.length > 0) {
      const promises = forms.map(async (form) => {
        formList = []
        if (!["no form #", "test form"].includes(form.formName)) {
          formList.push(form.formName)
          const formsResponse = await GenerateDocument(
            model,
            process.env.NEXT_PUBLIC_DEFAULT_FORMS_ISDRAFT === "true"
              ? true
              : false,
            false,
            formList,
            { "Content-Type": "application/json" },
            "blob",
            transactionType
          )
          const fileName = `${form.formName} - ${refNowithVersion}_${timestamp}.pdf`
          const uploadModal = {
            PolicyNumber: referenceNumber,
            DocumentType: fileName,
            UploadedFiles: [formsResponse],
            FolderName:
              transactionType === TransactionTypeMaster.ENDORSEMENT
                ? TransactionTypeMaster.ENDORSEMENT
                : TransactionTypeMaster.APPLICATION
          }

          await uploadFilesToDMS(model, uploadModal, "", false, fileName)
        }
      })
      // return true;
      // eslint-disable-next-line
      return await Promise.all(promises).then((resp) => {
        return true
      })
    } else {
      return false
    }
  } catch (error) {
    console.log("error", error)
  }
}

export const getLoginUserRole = (isLowerCase = false) => {
  const currentLoginState = getStoreState(storeKeys.LoggedInUserReducer)
  const Role = currentLoginState?.decodedJWT?.role.split(",").pop()
  return isLowerCase ? Role.toLowerCase() : Role
}

export const fetchCancellationReason = () => {
  const masterReducer = getStoreState(storeKeys.MasterReducer)
  const cancellationReasons = masterReducer.MasterCancellationReason
  const cancellationDropdowns = cancellationReasons.map((x) => {
    return {
      key: x.CancellationReason,
      label: x.CancellationReason,
      value: x.CancellationReason
    }
  })
  return cancellationDropdowns
}

export const premiumFormat = (num, placeholder = "Excluded") => {
  let formattedResp = ""
  try {
    if (num == "Excluded" || num == "0" || num == "") return "$0"
    if (typeof num == "string" && num.length > 0) {
      num = num.replaceAll(",", "")
    }
    if (parseInt(num) == 0 || (num && !isNaN(num) && isFinite(num))) {
      num = num.toString()
      return (formattedResp =
        "$" +
        parseFloat(num)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
    } else return "$0"
  } catch (error) {
    console.error("Utilities.Shared.Helpers.CurrencyFormat ", error)
    throw Error()
  }
}
export const comparePolicyDeductible = (prevPolicy, currentPolicy) => {
  if (
    prevPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "allOtherPerils"
    ).Value >
    currentPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "allOtherPerils"
    ).Value
  ) {
    toast.error(
      "Deductible cannot be reduced during the policy term. It can only be reduced at renewal. Deductible can be increased anytime."
    )
    return false
  }
  if (
    prevPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "hurricaneDeductible"
    ).Value >
    currentPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "hurricaneDeductible"
    ).Value
  ) {
    toast.error("Deductible cannot be reduced during the policy term.")
    return false
  }
  if (
    prevPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "waterDeductible"
    ).Value >
    currentPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "waterDeductible"
    ).Value
  ) {
    toast.error("Deductible cannot be reduced during the policy term.")
    return false
  }
  if (
    prevPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "roofDeducitble"
    ).Value >
    currentPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "roofDeducitble"
    ).Value
  ) {
    toast.error("Deductible cannot be reduced during the policy term.")
    return false
  }
  if (
    prevPolicy.Risks.Properties[0].PropertyRiskAttributes.RoofDeductibleCovA >
    currentPolicy.Risks.Properties[0].PropertyRiskAttributes.RoofDeductibleCovA
  ) {
    toast.error("Deductible cannot be reduced during the policy term.")
    return false
  }
  if (
    prevPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "identityTheft"
    ).Value !=
    currentPolicy.Risks.Properties[0].Coverages.find(
      (c) => c.Name == "identityTheft"
    ).Value
  ) {
    toast.error("Identity theft coverage cannot be changed midterm.")
    return false
  }
  return true
}
