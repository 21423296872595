const Menus = [
  {
    Key: "AccountHub",
    Label: "Account Hub",
    Src: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/sidebar-uiElement.png`,
    Href: "/dashboard/accounthub",
    Childs: []
  },
  {
    Key: "GenerateQuote",
    Label: "New Quote Indication",
    Src: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/sidebar-form.png`,
    Href: "/quote/landing",
    Childs: []
  },
  // {
  //   Key: "MyCommunicationHub",
  //   Label: "My Communication Hub",
  //   Src: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/communication.png`,
  //   Href: "/dashboard/my-communication-hub",
  //   Childs: [],
  // },
  // {
  //   Key: "Analytics",
  //   Label: "Analytics",
  //   Src: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/analyticsicon.png`,
  //   Href: "/dashboard/analytics",
  //   Childs: []
  // }
]
export { Menus }
