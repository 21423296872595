import {
  axiosKeys,
  commonKeys,
  storeKeys
} from "@cogitate/ui-utils-core-test/dist/data/constants/keys"
import {
  alertMsgs,
  consoleLogs
} from "@cogitate/ui-utils-core-test/dist/data/constants/messages"
import { GetPushNotificationAPI } from "@cogitate/ui-utils-core-test/dist/utilities/apollo/operations"
import { getStoreState } from "@cogitate/ui-utils-core-test/dist/utilities/pages/shared"
import { callAPI } from "@cogitate/ui-utils-core-test/dist/utilities/shared/helpers"
import moment from "moment"
import NProgress from "nprogress"
import { toast } from "react-toastify"
import { DMS_AllowedFiles, ignoreFileView } from "ui/data/constants/keys"

const DocumentDateFormatter = (date) => {
  return `${moment(date).format("L")} ${moment(date).format("h:mm:ss A")}`
}

const DocumentsColumns = [
  {
    dataField: "foldername",
    text: "Folder Name",
    sort: false,
    headerStyle: () => {
      return { width: "13%" }
    }
  },
  // {
  //     dataField: "transactioncount",
  //     text: "TXN No",
  //     sort: false,
  //     headerStyle: () => {
  //         return { width: "10%", textAlign: "center" };
  //     },
  // },
  {
    dataField: "username",
    text: "User",
    sort: false,
    headerStyle: () => {
      return { width: "15%", textAlign: "center" }
    }
  },
  {
    dataField: "documenttype",
    text: "DOCUMENT DESCRIPTION",
    sort: false,
    headerStyle: () => {
      return { width: "13%", textAlign: "center" }
    }
  },
  {
    dataField: "filename",
    text: "FILE NAME",
    sort: false,
    headerStyle: () => {
      return { width: "15%", textAlign: "center" }
    }
  },
  {
    dataField: "TransactionDate",
    text: "Uploaded / Created Date",
    sort: false,
    headerStyle: () => {
      return { width: "20%", textAlign: "center" }
    },
    customFormatter: DocumentDateFormatter
  },
  {
    dataField: "effectivedate",
    text: "EFFECTIVE DATE",
    sort: false,
    headerStyle: () => {
      return { width: "17%" }
    },
    hidden: true
  },
  {
    dataField: "Uri",
    text: "FileUrl",
    sort: false,
    headerStyle: () => {
      return { width: "20%" }
    },
    hidden: true
  }
]

const EmailDocument = async (model, row) => {
  const summary = getStoreState(storeKeys.PolicyReducer)
  NProgress.start()
  const file = row.Uri.split("/")
  const emailAgent = await GetPushNotificationAPI(
    summary,
    "sendDocument",
    model.EmailId,
    [],
    [],
    model.Comments,
    "",
    row.Uri,
    file[file.length - 1].replaceAll(":", "_")
  )
  if (emailAgent) toast.success("Document Mailed Successfully!")
  else toast.error("Document Mailed Failed!")
  NProgress.done()
  return
}

const getFilesListFromDMS = async (requestInput) => {
  try {
    const apiBaseURL = `${process.env.NEXT_PUBLIC_DMS_API_BASE_URL}/`
    const apiRelativeURL =
      process.env.NEXT_PUBLIC_DMS_API_GET_DOC_LIST_RELATIVE_URL
    const loggedInuser = getStoreState(storeKeys.LoggedInUserReducer).decodedJWT
    const {
      cloudBlobContainer: containerName = commonKeys.EmptyString,
      carrierCode = commonKeys.EmptyString,
      coverholderCode = commonKeys.EmptyString,
      lobCode = commonKeys.EmptyString,
      stateCode = commonKeys.EmptyString,
      referenceNumber = commonKeys.EmptyString
    } = requestInput

    const apiURL = `${apiBaseURL}${apiRelativeURL}?container=${containerName}&blobpath=${carrierCode}${coverholderCode}/${lobCode}/${stateCode}/${referenceNumber}`
    let apiResponse = await callAPI(
      axiosKeys.GetAction,
      apiURL,
      null,
      { Authorization: process.env.NEXT_PUBLIC_DMS_API_AUTH },
      ""
    )

    if (
      [commonKeys.RoleAgent, commonKeys.RoleBuilder].includes(
        loggedInuser?.role
      )
    ) {
      apiResponse = apiResponse.filter(
        (doc) => doc.foldername != "Third Party Documents"
      )
    }
    const response = apiResponse?.map((obj) => {
      const { transactiondate, ...rest } = obj
      return {
        TransactionDate: transactiondate,
        ...rest
      }
    })
    console.log(response, "files")
    response?.sort((a, b) => {
      return a.foldername?.localeCompare(b.foldername)
    })
    return response
  } catch (err) {
    console.error(
      consoleLogs.Utilities.Pages.Application.GetFilesListFromDMS,
      err
    )
    throw err
  }
}

export const getFormData = (policy, file) => {
  const date = moment()
  const loggedInuser = getStoreState(storeKeys.LoggedInUserReducer)

  const infoId = policy.PolicyNumber ? policy.PolicyNumber : policy.QuoteNumber
  const metadata = {
    PolicyInfoId: infoId,
    PolicyNumber: policy.PolicyNumber,
    CarrierCode: policy.Attributes.Carrier,
    CoverholderCode: policy.Attributes.Coverholder,
    Lob: policy.Attributes.Lob,
    State: policy.Attributes.State,
    TransactionCount: policy.Transaction.Number.toString(),
    TransactionType: policy.Transaction.Type,
    TransactionDate: moment().toDate(),
    UserName: `${loggedInuser.decodedJWT.FirstName} ${loggedInuser.decodedJWT.LastName}`,
    Filename: `${infoId}_${policy.Transaction.Type}_${date}.pdf`,
    Applicationstatus: policy.PolicyStatus
  }

  metadata.Filename =
    file?.name !== undefined
      ? file?.name
      : `${infoId}_${policy.Transaction.Type}_${date}.pdf`

  const blobPath = `${policy.Attributes.Carrier}${
    policy.Attributes.Coverholder
  }/${policy.Attributes.Lob}/${policy.Attributes.State}/${
    policy.PolicyNumber
  }/${metadata.Filename}`

  return {
    containerName: process.env.NEXT_PUBLIC_DMS_API_NAME,
    blobPath,
    metaData: metadata
  }
}

export const toastify = (msg, err) => {
  if (err) {
    toast.error(msg)
  } else {
    toast.success(msg)
  }
}

export const commonDocsConfigs = {
  groupColumnName: "foldername",
  dmsContainerName: "dms",
  docactionpos: "center",
  useRefresh: true,
  folderNameOptions: {
    options: [
      { Key: "Quote", label: "Quote", value: "Quote" },
      { Key: "Application", label: "Application", value: "Application" },
      { Key: "Policy", label: "Policy", value: "Policy" },
      { Key: "Cancellation", label: "Cancellation", value: "Cancellation" },
      { Key: "Reinstate", label: "Reinstate", value: "Reinstate" },
      { Key: "Endorsement", label: "Endorsement", value: "Endorsement" },
      { Key: "Renewal", label: "Renewal", value: "Renewal" }
    ],
    primaryColor: "#2d75ff"
  },
  tableCols: DocumentsColumns,
  getFormData,
  getFilesListFromDMS,
  EmailDocument: (model, row) => EmailDocument(model, row),
  env: {
    NEXT_PUBLIC_DMS_API_BASE_URL: process.env.NEXT_PUBLIC_DMS_API_BASE_URL,
    NEXT_PUBLIC_DMS_API_AUTH: process.env.NEXT_PUBLIC_DMS_API_AUTH
  },
  fileProps: {
    allowedFiles: DMS_AllowedFiles,
    fileSize: 100 * 1024 * 1024,
    maxNumberOfFiles: 10
  },
  extras: { uploadDocSize: "md", uploadDocDialogClassName: "modal-90w" },
  useNewDocFeature: {
    useMultiDocFeature: true,
    useHiddenFeature: true,
    useDeleteFeature: true
  },
  useFileViewer: true,
  toast: toastify,
  expandRowProps: { showExpandColumn: true, expandByColumnOnly: false },
  ignoreFileView: ignoreFileView,
  roleBasedFeatures: {
    deleteFeature: ["underwriter"],
    hideFeature: ["underwriter"]
  }
}
